var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form',{on:{"submit":_vm.onSubmit}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Title","label-for":"title"}},[_c('b-form-input',{attrs:{"id":"title"},model:{value:(_vm.userData.title),callback:function ($$v) {_vm.$set(_vm.userData, "title", $$v)},expression:"userData.title"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Company","label-for":"company"}},[_c('v-select',{attrs:{"options":_vm.$store.state.job.companies,"reduce":function (val) { return val.id; },"clearable":false,"input-id":"companyId","label":"name"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
return [_c('span',{staticClass:"ml-50 d-inline-block align-middle"},[_vm._v(" "+_vm._s(name))])]}},{key:"selected-option",fn:function(ref){
var name = ref.name;
return [_c('span',{staticClass:"ml-50 d-inline-block align-middle"},[_vm._v(" "+_vm._s(name))])]}}]),model:{value:(_vm.userData.companyId),callback:function ($$v) {_vm.$set(_vm.userData, "companyId", $$v)},expression:"userData.companyId"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Begin Date","label-for":"due-date"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                  dateFormat: 'Z',
                  altInput: true,
                  altFormat: 'd-m-Y',
                }},model:{value:(_vm.userData.beginDateUtc),callback:function ($$v) {_vm.$set(_vm.userData, "beginDateUtc", $$v)},expression:"userData.beginDateUtc"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"End Date","label-for":"End-date"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                  dateFormat: 'Z',
                  altInput: true,
                  altFormat: 'd-m-Y',
                }},model:{value:(_vm.userData.endDateUtc),callback:function ($$v) {_vm.$set(_vm.userData, "endDateUtc", $$v)},expression:"userData.endDateUtc"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Assignee","label-for":"assignee"}},[_c('v-select',{staticClass:"assignee-selector",attrs:{"options":_vm.assigneeOptions,"label":"userFullName","input-id":"userId","multiple":""},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                var userFullName = ref.userFullName;
return [_c('span',{staticClass:"ml-50 d-inline-block align-middle"},[_vm._v(" "+_vm._s(userFullName))])]}},{key:"selected-option",fn:function(ref){
                var userFullName = ref.userFullName;
return [_c('span',{staticClass:"ml-50 d-inline-block align-middle"},[_vm._v(" "+_vm._s(userFullName))])]}}]),model:{value:(_vm.userData.users),callback:function ($$v) {_vm.$set(_vm.userData, "users", $$v)},expression:"userData.users"}})],1)],1),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12","md":"6"}},[_c('b-form-group',[_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","inline":""},model:{value:(_vm.userData.isActive),callback:function ($$v) {_vm.$set(_vm.userData, "isActive", $$v)},expression:"userData.isActive"}},[_vm._v(" Job Disabled ")])],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Description","label-for":"description"}},[_c('b-form-textarea',{attrs:{"rows":"3","max-rows":"6","id":"description"},model:{value:(_vm.userData.description),callback:function ($$v) {_vm.$set(_vm.userData, "description", $$v)},expression:"userData.description"}})],1)],1)],1),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"success","type":"submit"}},[_vm._v(" Save ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }