<template>
  <div>
    <!-- Media -->



    <!-- User Info: Input Fields -->
    <b-form @submit="onSubmit">
      <b-row>
        <!-- Field:  -->
        <b-col cols="12" md="6">
          <b-form-group label="Title" label-for="title">
            <b-form-input id="title" v-model="userData.title" />
          </b-form-group>
        </b-col>
        

      <b-col cols="12" md="6">
           <b-form-group label="Company" label-for="company">
             <v-select
                    v-model="userData.companyId"
                    :options="$store.state.job.companies"
                    :reduce="(val) => val.id"
                    :clearable="false"
                    input-id="companyId"
                    label="name"
                  >
                   <template #option="{name}">
                 
                  <span class="ml-50 d-inline-block align-middle"> {{name}}</span>
                </template>

                <template #selected-option="{name}">
                

                  <span class="ml-50 d-inline-block align-middle"> {{name}}</span>
                </template>
                  </v-select>
          </b-form-group>
        </b-col>
         
            <b-col cols="12" md="6">
              <b-form-group label="Begin Date" label-for="due-date">
                <flat-pickr
                  v-model="userData.beginDateUtc"
                  
                  class="form-control"
                  :config="{
                    dateFormat: 'Z',
                    altInput: true,
                    altFormat: 'd-m-Y',
                  }"
                />
              </b-form-group>
            </b-col>

               <b-col cols="12" md="6">
              <b-form-group label="End Date" label-for="End-date">
                <flat-pickr
                  v-model="userData.endDateUtc"
                  class="form-control"
                  :config="{
                    dateFormat: 'Z',
                    altInput: true,
                    altFormat: 'd-m-Y',
                  }"
                />
              </b-form-group>
            </b-col>
        <!-- Field: Full Name -->
       
    <b-col cols="12" md="6">
              <!-- Assignee -->
              <b-form-group label="Assignee" label-for="assignee">
                <v-select
                  v-model="userData.users"
                  :options="assigneeOptions"
                  label="userFullName"
                  class="assignee-selector"
                  input-id="userId"
                  multiple
                >
                  <template #option="{ userFullName }">
                    <span class="ml-50 d-inline-block align-middle">
                      {{ userFullName }}</span
                    >
                  </template>

                  <template #selected-option="{ userFullName }">
                    <span class="ml-50 d-inline-block align-middle">
                      {{ userFullName }}</span
                    >
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
         <b-col cols="12" md="6" class="mt-2">
      
           <b-form-group>
            <b-form-checkbox
              v-model="userData.isActive"
              name="check-button"
              switch
              inline
            >
              Job Disabled
            </b-form-checkbox>
          </b-form-group>
          </b-col>


  <b-col cols="12" md="6">
          <b-form-group label="Description" label-for="description">
            <b-form-textarea rows="3"
                max-rows="6" id="description" v-model="userData.description" />
          </b-form-group>
        </b-col>

        


      </b-row>


      <!-- Action Buttons -->
      <b-button
        variant="success"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
        type="submit"
      >
        Save
      </b-button>
  
      <!--   <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Reset
    </b-button>-->
    </b-form>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormTextarea,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import useUsersList from "../job-list/useJobList";
import store from "@/store";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import { Vue } from "vue-property-decorator";
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css';


export default {
  components: {
    BButton,
    ToastificationContent,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    BFormTextarea,
    flatPickr
  },
  data() {
    return {
      file: null,
    };
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  directives: {
    Ripple,
  },
  methods: {
    deletePic() {
      store
        .dispatch("job/deletePic", {
          id: this.userData.id,
        })
        .then((response) => {
          this.userData.companyLogo = "";
        })
        .catch((error) => {
          for (const [key, value] of Object.entries(
            error.response.data.errors
          )) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "error",
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: value,
              },
            });
          }
        });
    },
  
  },
  setup(props) {
    const { resolveUserRoleVariant,assigneeOptions } = useUsersList();

    const companies = ref([
      { name: "amatis", id: 4 }]);

    const onSubmit = (event) => {
      event.preventDefault();
const data={
  ...props.userData,
  companyLogo: null
}
      
      store
        .dispatch("job/editUser", data)
        .then((response) => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: "User updated successfully",
              icon: "AlertTriangleIcon",
              variant: "success",
            },
          });
          router.push("/apps/job/list").catch(() => {});
          // router.go("/apps/users/list").catch(() => {});
        })
        .catch((error) => {
          for (const [key, value] of Object.entries(
            error.response.data.errors
          )) {
            Vue.$toast({
              component: ToastificationContent,
              props: {
                title: "error",
                icon: "AlertTriangleIcon",
                variant: "danger",
                text: value,
              },
            });
          }

          if (error.response.status === 404) {
            skillsData.value = undefined;
          }
        });
    };

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        // eslint-disable-next-line no-param-reassign

        props.userData.companyLogo = base64;

        // console.log("base64",base64);

        store
          .dispatch("job/addPhoto", {
            id: props.userData.id,
            base64: refInputEl.value.files[0],
          })
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            for (const [key, value] of Object.entries(
              error.response.data.errors
            )) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "error",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                  text: value,
                },
              });
            }
           
          });
      }
    );

    return {
      resolveUserRoleVariant,
      avatarText,
      companies,
      onSubmit,
      assigneeOptions,
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
