<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">


        <user-edit-tab-account
          :user-data="userData"
          class="mt-2 pt-75"
        />
  
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import userStoreModule from '../jobStoreModule'
import UserEditTabAccount from './JobEditTabAccount.vue'
import UserEditTabInformation from './JobEditTabInformation.vue'
import UserEditTabSocial from './JobEditTabSocial.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
     ToastificationContent,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    UserEditTabAccount,
    UserEditTabInformation,
    UserEditTabSocial,
  },
  setup() {
    const userData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'job'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('job/fetchCompanyId', { id: router.currentRoute.params.id })
      .then(response => { userData.value = response.data.data })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.response.data.messages[0].messageText,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })

    return {
      userData,
    }
  },
}
</script>

<style>

</style>
